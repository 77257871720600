export default [
  {
    path: '/iframe',
    name: 'iframe',
    component: () => import(/* webpackChunkName: "iframe" */ '../views/index/iframe.vue')
  },
  {
    path: '/gwql',
    name: 'gwql',
    component: () => import(/* webpackChunkName: "gwql" */ '../views/index/gwql.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/index/news.vue')
  },
  {
    path: '/shareDetail',
    name: 'shareDetail',
    component: () => import(/* webpackChunkName: "shareDetail" */ '../views/share/shareDetail.vue')
  },
  {
    path: '/cash',
    name: 'cash',
    component: () => import(/* webpackChunkName: "cash" */ '../views/mine/cash.vue')
  },
  {
    path: '/bindBank',
    name: 'bindBank',
    component: () => import(/* webpackChunkName: "bindBank" */ '../views/mine/bindBank.vue')
  },
  {
    path: '/real',
    name: 'real',
    component: () => import(/* webpackChunkName: "real" */ '../views/mine/real.vue')
  },
  {
    path: '/cashDetail',
    name: 'cashDetail',
    component: () => import(/* webpackChunkName: "cashDetail" */ '../views/mine/cashDetail.vue')
  },
  {
    path: '/moneyDetail',
    name: 'moneyDetail',
    component: () => import(/* webpackChunkName: "moneyDetail" */ '../views/mine/moneyDetail.vue')
  },
  {
    path: '/appxz',
    name: 'appxz',
    component: () => import(/* webpackChunkName: "appxz" */ '../views/mine/appxz.vue')
  },
  {
    path: '/setPwd',
    name: 'setPwd',
    component: () => import(/* webpackChunkName: "setPwd" */ '../views/mine/setPwd.vue')
  },
  {
    path: '/setBankPwd',
    name: 'setBankPwd',
    component: () => import(/* webpackChunkName: "setBankPwd" */ '../views/mine/setBankPwd.vue')
  },
  {
    path: '/address',
    name: 'address',
    component: () => import(/* webpackChunkName: "address" */ '../views/mine/address.vue')
  },
  {
    path: '/buyOrder',
    name: 'buyOrder',
    component: () => import(/* webpackChunkName: "buyOrder" */ '../views/mine/buyOrder.vue')
  }
  
  
]